var toastr = require('toastr');
var iScroll = require('iscroll');

MainController.$inject = ['$scope', '$http', '$timeout', '$location', '$rootScope', '$state', '$stateParams', 'Util', 'AfreecaStorageFactory', 'FavoriteService', 'PmService',
	'$sessionStorage', 'LoginManager', 'LaterviewFactory', 'gettextCatalog', 'MainService', 'NoteService', 'gtext', 'HistoryState', 'VideoListService', 'NotificationService', 'VideoPlaylistService','CookieFactory'
];

function MainController($scope, $http, $timeout, $location, $rootScope, $state, $stateParams, Util, AfreecaStorageFactory, FavoriteService, PmService,
	$sessionStorage, LoginManager, LaterviewFactory, gettextCatalog, MainService, NoteService, gtext, HistoryState, VideoListService, NotificationService, VideoPlaylistService,CookieFactory) {

	// 타이틀
	$rootScope.dTitle = '';

	//어두운 모드 변수
	$rootScope.bDark = false;
	$rootScope.bNotificationClick = false;

	$rootScope.mainFooterCheck = false;

	$scope.bSponserTooltipToggle =  CookieFactory.get("openSponserTooltip") =="ON" ? false : true;

	$scope.aPlaylists = []; //재생목록

	$scope.bInfoOpen = false;

	if(gettextCatalog.currentLanguage){
		if(gettextCatalog.currentLanguage == 'en_US' || gettextCatalog.currentLanguage == 'th_TH'){
			$rootScope.dTitle = 'AfreecaTV Mobile';
		}else if(gettextCatalog.currentLanguage == 'ja_JP'){
			$rootScope.dTitle = 'アフリカTVモバイル';
		}else if(gettextCatalog.currentLanguage == 'zh_CN'){
			$rootScope.dTitle = 'AfreecaTV网页版';
		}else if(gettextCatalog.currentLanguage == 'zh_TW'){
			$rootScope.dTitle = 'AfreecaTV網頁版';
		}
	}
	/** 일본 환전 불가 레이어 관련 로직 */
	$scope.bJpExchangeCloseLayer = CookieFactory.get("JpExchangeCloseLayer") == "OFF"  ? false : true;	// 베트남 종료 안내 레이어 노출 여부
	$scope.bJpInfoChecked = true;			// 다시 보지 않기 체크 여부

	$scope.closeJpInfoLayer = function() {		//일본 종료 안내 레이어 닫기
		if ($scope.bJpInfoChecked) {
			CookieFactory.set('JpExchangeCloseLayer',"OFF", 30);
		}
		$scope.bJpExchangeCloseLayer = false;
	}

	$scope.MovecloseJpInfo = function() {		//일본 종료 안내 공지 새창
		if ($scope.bJpInfoChecked) {
			CookieFactory.set('JpExchangeCloseLayer',"OFF", 30);
		}
		window.open(GHELP_AFREECATV + "/index.php?pt=notice_detail&no=697&page=1&field=&keyword=", "jp_exchange_close_layer");
	}
	


	$scope.country_code = gettextCatalog.currentLanguage

	if($rootScope.dTitle){
		document.title = $rootScope.dTitle;
	}

	//뷰가 바뀌는 과정에서 scrollTop()이 0이 되어 $stateChangeStart 로 변경
	//$stateChangeSuccess => $stateChangeStart
	$rootScope.$on('$stateChangeStart', function (ev, to, toParams, from, fromParams) {
		if ($('body').scrollTop() != 0) {
			nPosition = $('body').scrollTop();
		}

		$rootScope.previousState = from.name;

		HistoryState.set();

		// 서브메뉴 초기화.
		$rootScope.initSubmenu();
	});

	var _state = null;
	$scope.checkNewNote = function () {
		//새로운 쪽지갯수
		NoteService.receviceCount().then(function (response) {
			$rootScope.new_count = response.data.new_count;
			$rootScope.$apply();
		}, function () {
			$rootScope.new_count = $rootScope.new_count > 0 ? $rootScope.new_count : 0;
			$rootScope.$apply();
		})
	}

	/**
	 * PM 전체점검
	 */
	$scope.checkPm = function () {
		PmService.check({
				szItem: 'full',
				szFunction: 'full',
				szAgent: 'web'
			})
			.then(function (response) {}, function (error) {
				// 실질적인 점검 로직 - 점검 로직 어드민배포 이후 return result -1200 응답
				// www.afreecatv.com 로 가져오되 constant js 가져올때 타이밍 이슈로 못 가져올 경우 rootScope 내용에서 가져오도록 수정
				location.href = `${WWW_AFREECATV||$rootScope.$const.WWW_AFREECATV}/pm/maintenance_notice.html`;
				// $state.go('pm', {사용자가 삭제한 대화입니다.
				// 	pm: error.data
				// });
				return;
			})
	};


	window.addEventListener("message", function (event) {
		if (event.origin == AUTH_M_AFREECATV &&
			event.data == 'signed') {
			console.log("signed");
			event.source.postMessage("received", event.origin);
			//로그인 성공
			LoginManager.check();
			//좌측메뉴 닫기
			$('body').removeClass('menu_on');


			// 좌측메뉴를 닫았기 때문에, #menu 해쉬태그가 있던 상태라면 제거해줌
			if ($location.url().indexOf('#menu') > 0) {
				var szChangeHashUrl = $location.url().replace("#menu", "");
				$location.url(szChangeHashUrl);
			}
			//페이지 이동
			if (_state) {
				$state.go(_state);
				_state = null;
			}
			$scope.checkNewNote();
		}
	});


	$rootScope.bIsWebView = afreeca.link.isWebview();
	$scope.bIOSWeb = (Util.getOS() == 'ios');
	/**
	 * 3.0 지원버전 체크
	 */
	$scope.bGingerbreadHigher = Util.isGingerbreadHigher();
	/**
	 * 메뉴 불러오기
	 * @type Date
	 */
	if(location.href.indexOf('direct') === -1 && location.href.indexOf('embed') === -1) {
        MainService.menu().then(function (response) {
            $rootScope.groups = response.data;
            $rootScope.$broadcast('menuCompleteEvent');
            $timeout(function () {
                //좌측 메뉴 스크롤.
                //new IScroll('#left_menu', {hScrollbar: false, vScrollbar: false, click: true});
                new iScroll.iScroll('left_menu', {
                    hScrollbar: false,
                    vScrollbar: false
                });
            }, 500);
        })
	}


	$('#lightbox').on('touchstart', function (e) {
		e.stopPropagation();
		e.preventDefault();
		//갤럭시노트2등에서 뒤 이벤트로 실행됨
		setTimeout(function () {
			$scope.menuHandler();
		}, 500);
	});

	// ios dimmed 뒤 스크롤 방지.
	$('.dimmed').on('touchmove', function (e) {
		e.stopPropagation();
		e.preventDefault();

		$rootScope.closeActionLayer();
	}).delegate('#action_sheet','touchmove', function(e){
		e.stopPropagation();
		// e.preventDefault();
	});

	//페이지 UV
	$.get(M_AFREECATV + "/ma.php?category=mobileweb&action=page&label=" + $location.path().split('/')[1]);

	//UV //삭제
	$.get(M_AFREECATV + "/ma.php?category=main&action=ver1&label=" + Util.getOS());


	/**
	 * 페이지 로딩시 #menu,#query 등의 2차 해시 붙을경우 제거
	 */
	if ($location.hash()) {
		$location.hash('');
	}

	/**
	 * Scheme 실행
	 * @param {string} szScheme
	 */
	window.action = $rootScope.action = function (szScheme) {
		Util.action(szScheme);
	};
	/**
	 * 방송국 이동
	 * @param {string} user_id
	 */
	$scope.goStation = function (user_id) {
		Util.goStation(user_id);
	};


	/**
	 * 추천 받지 않기
	 * @param {object} oGroup
	 * @param {object} oItem
	 * @param {int} nIdx
	 * @param {string} szPageType
	 */
	$scope.rejectRecommend = function (oGroup, oItem, nIdx, szPageType) {
		if (confirm(gtext("\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?", oItem.user_nick))) {

			MainService.rejectRecommend($rootScope.member.user_id, oItem.user_id, szPageType).then(function () {
				oGroup.splice(nIdx, 1);
				$scope.$digest();
				alert(gtext("적용 되었습니다."));
			})
		}
	};


	/**
	 * 방송,동영상 의 Scheme 실행
	 * @param {Object} obj
	 */

	$rootScope.liveAction = function (broadInfo) {
		if (broadInfo.broad_no) {
			if (broadInfo.grade >= 19) {
				var szAdultText = gtext('청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?');
				//								var szAdultText = $rootScope.getText('adult_broad_text', '19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?');
				if (confirm(szAdultText)) {
					Util.live(broadInfo.user_id, broadInfo.broad_no);
				}
				return;
			} else if (broadInfo.is_password != "0") {
				var szPasswordText = gtext('비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?');
				//								var szPasswordText = $rootScope.getText('secret_broad_text', '비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?');
				if (confirm(szPasswordText)) {
					Util.live(broadInfo.user_id, broadInfo.broad_no);
				}
				return;
			}

	}
		Util.action(broadInfo.scheme);
	};

	/**
	 * 검색 페이지로 이동
	 * @returns {undefined}
	 */
	$scope.movePageKeyword = function () {
		$scope.sendClickLog();
		$state.go('keyword', { search_tab: $rootScope.searchTab });
	};

	/**
	 * 검색 키워드 초기화
	 * @returns {undefined}
	 */
	$scope.removeQuery = function () {
		$scope.query = '';
		$scope.movePageKeyword();
	};

	/**
	 * CLICK 로그 전송
	 * @returns {undefined}
	 */
	$scope.sendClickLog = function () {
		window.collector.click({
			code_type: 'search_input_field',
			action: 'click_tab',
			tab_type: 'all'
		});
	};

	// 스크롤 go top 버튼 노출여부
	$scope.setGoTop = function () {
		$('#btn_pagetop').hide();
		var oTimer;
		$(window).scroll(function () {
			$('#btn_pagetop').hide();
			clearTimeout(oTimer);
			oTimer = setTimeout( function(){
				if($(window).scrollTop() > 0){
					$('#btn_pagetop').show();
				}
			} , 100);
		});
	};

	// 스크롤 go top
	$scope.goTop = function () {
		// vod 필터 관련.
		if($state.current.name == 'video'){
			$('.filter_area').each(function(){
				$(this).removeClass('fixed').css({ top: 'auto', transform: "translateY(0px)" });
			})
		}
		$('body, html').animate({
			scrollTop: 0
		}, 500);
	};

	// reload
	$scope.reloadPage = function () {
		window.location.reload();
	};

	// vod
	$scope.goVod = function () {
		$("#vod_tab").trigger("click");
	}

	//메뉴버튼
	$scope.menuHandler = function () {
		if ($('body').hasClass('menu_on')) {
			$('body').removeClass('menu_on');
		} else {
			$('body').removeClass('menu_on').addClass('menu_on');
		}

		//IOS는 예외처리 (백버튼이 없으므로)
		if (navigator.userAgent.toLowerCase().match('/ipad|iphone|ipod/') != null) {
			return;
		}

		if ($location.hash() === 'menu') {
			window.history.back();
		} else {
			$location.hash('menu');
		}
	};

	// 알림뱃지 갱신
	$scope.checkNewNotification = function () {
		NotificationService.checkNewBadge().then(function (response) {
			$rootScope.new_NotiBadge = response.data.badge_flag;
			$rootScope.$apply();
		}, function () {
			$rootScope.new_NotiBadge = $rootScope.new_NotiBadge > 0 && !$rootScope.bNotificationClick ? $rootScope.new_NotiBadge : 0;
			$rootScope.$apply();
		});
	}

	/**
	 * 앱실행하기
	 */
	$scope.goApp = function () {
		Util.app();
	}
	$scope.myinfoHandler = function () {
		$('body').removeClass('menu_on');
		$state.go("myinfo");
	}
	$scope.iteminfoHandler = function () {
		$('body').removeClass('menu_on');
		$state.go("iteminfo", { entry_location : 'm_starballoonitem' });
	}

	// 왼쪽메뉴 링크 클릭시
	$scope.leftMenu = function (menu) {
		//메뉴 이동시 알림뱃지 갱신
		$scope.checkNewNotification();
		//메뉴닫기
		location.replace('#' + $location.path());
		$('body').removeClass('menu_on');
		$scope.selectMenu = menu;

		if(menu.id === "vod_tab"){
			delete $sessionStorage.oVodSubmenu;
			delete $sessionStorage.oVodTab;
			delete $sessionStorage.oVodCategory;
			delete $sessionStorage.szVodFavoriteUserId;
      delete $sessionStorage.oVodLaterView;
		}

		Util.action(menu.scheme, menu.id);
	};

	$rootScope.$on('$locationChangeStart', function (evt, next, current) {
		$rootScope.szPreviousUrl = current;

		/**
		 * 액션 시트 닫힘.
		 */
		if($rootScope.bShowActionSheet){
			evt.preventDefault();
			$rootScope.bShowActionSheet = false;
		}
		$scope.checkNewNotification();
	});


	$rootScope.$on('$locationChangeSuccess', function (evt, next, current) {
		/**
		 * 백버튼으로 메뉴 사라지게
		 */
		if (current.indexOf('#menu') > 0 && next.indexOf('#menu') < 0) {
			$('body').removeClass('menu_on');
		}

		/**
		 * search페이지가 아닌곳에서는 무조건 searchBox 감추기
		 */
		if (next.indexOf('#/search') < 0) {
			//검색
			$('#searchBox').addClass('hide');
		}

		/**
		 * 모웹 홈에 첫번째 카테고리 진입 했을때만 footer(이용약관, 개인정보방침, 사업자 정보) 노출이기 때문에 home 외에는 노출 안되도록 false 처리
		 */
		if($state.current.name != "home") {
			$rootScope.mainFooterCheck = false;
		}

		$rootScope.bNote = next.indexOf('/note') >= 0 ? true : false;

		$rootScope.bVidoWbg = $location.url().split('/')[1] == 'video' ? true : false;
		$rootScope.bVodClass = $location.url().split('/')[1] == 'video' ? true : false;

		$rootScope.bMypoint = next.indexOf('/mypoint') >= 0 ? true : false;

		// 다음 페이지에 대한 어두운 모드 적용
		$rootScope.bDark = Util.checkDark(next);

		//헤더 알림뱃지 갱신
		$scope.checkNewNotification();

	});

	$rootScope.bNote = $location.url().indexOf('/note') >= 0 ? true : false;
	$rootScope.bMypoint = $location.url().indexOf('/mypoint') >= 0 ? true : false;

	//현재 페이지에 대한 어두운 모드 적용
	$rootScope.bDark = Util.checkDark($location.absUrl());

	/**
	 * 로그인 후!!!!
	 */
	$rootScope.$watch('member', function (newValue, oldValue) {
		if ($rootScope.member) {

			// 국가 정보
			$rootScope.szNation = Util.getNationByNationalCode($rootScope.member.nationality_cd);

			// 즐겨찾기 추가할 대상이 있을경우 로그인후 추가한다. (로그인하기전 즐겨찾기를 시도하였을때)
			if ($sessionStorage.favorateUserId) {
				$rootScope.addFavoriteHandler($sessionStorage.favorateUserId, $sessionStorage.logData);
			}

			//헤더 알림뱃지 갱신
			$scope.checkNewNotification();
		} else {
			$rootScope.new_NotiBadge = 0;
		}
	}, true);

	// PM : main페이지 IN, OUT에시 watch
	$rootScope.$watch('bIsPm', function (newValue, oldValue) {
		$scope.checkPm();
	});

	// 중국어 // 외국어 셋팅
	// 단말기셋팅이 중국어일경우 수행되며 그이외에서는 Directive, 데이터 호출 자체를 수행하지않음.
	$scope.setLanguage = function () {
		//html lang 추가
		document.documentElement.lang = gettextCatalog.currentLanguage.split('_')[0];

		//$rootScope.szLanguage = Util.language(); // zh, en, ko
		$('body').removeClass($rootScope.szLanguage).addClass($rootScope.szLanguage); // body에 language class 추가

		if ($rootScope.szLanguage != 'ko') {

			// 중국 사용자 통계 (임시로 추가) //삭제
			if ($rootScope.szLanguage == 'zh') {
				$.get(M_AFREECATV + '/ma.php?category=mobileweb&action=china_page&label=' + $location.path().split('/')[1]);
			}

			var szTranslateUrl = AFEVENT_AFREECATV + '/api/get_translation_api.php?device_type=MOBILE&lang=' + $rootScope.szLanguage + '&';
			var szLastLanguageDateKey = 'szLastLanguageDate_' + $rootScope.szLanguage;
			var szLanguageDataKey = 'oLanguage_' + $rootScope.szLanguage;

			//고정 데이터 갱신여부확인
			$.ajax({
				url: szTranslateUrl + 'szWork=GETHEADER&',
				dataType: "jsonp",
				jsonpCallback: "callback",
				success: function (oRes) {
					var szLastUpdateDate = new Date(parseInt(oRes.update_date) * 1000);
					if (!AfreecaStorageFactory.getItem(szLastLanguageDateKey) ||
						!AfreecaStorageFactory.getItem(szLanguageDataKey) ||
						new Date(AfreecaStorageFactory.getItem(szLastLanguageDateKey)) < szLastUpdateDate) {
						//고정 데이터 갱신여부확인
						$.ajax({
							url: szTranslateUrl + 'szWork=LIST&',
							dataType: "jsonp",
							jsonpCallback: "callback",
							success: function (oResult) {
								$rootScope[szLanguageDataKey] = oResult;
								AfreecaStorageFactory.setItem(szLastLanguageDateKey, szLastUpdateDate); // szLastLanguageDate_en|zh
								AfreecaStorageFactory.setItem(szLanguageDataKey, angular.toJson(oResult)); // oLangage_en|zh
							}
						});
					} //
					else {
						$rootScope[szLanguageDataKey] = $.parseJSON(AfreecaStorageFactory.getItem(szLanguageDataKey));
					}

				}
			});
		}
	};


	$rootScope.login = function (state) {
		_state = state //이동위치 빽업
		//비로그인 일경우
		if (LoginManager.isLogin() == false) {
			//비디오포터 예외처리
			if (/videoportal/.test(navigator.userAgent) || document.location.href.indexOf('entry_platform=ollehmobile') > 0) {
				location.href = AUTH_M_AFREECATV + "/login?url=" + encodeURIComponent(location.href);
			} else {
				window.open(AUTH_M_AFREECATV + "/login?type=popup", "mobile_login");
			}
		} else {
			LoginManager.check();
		}
	}

	/*
	 * ***************************************************
	 *
	 * 즐겨찾기 추가
	 *
	 * ***************************************************
	 */
	$rootScope.addFavoriteHandler = function (szUserId, oLogData) {
		
		if (!$rootScope.member) {
			if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?"))) {
				// 로그인후에 자동으로 즐겨찾기 추가하기위함.
				$sessionStorage.favorateUserId = szUserId;
				$rootScope.login();
			}
			return;
		}

		oLogData['join_cc'] = $rootScope.member.nationality_cd;
		delete $sessionStorage.favorateUserId;
		FavoriteService.add(szUserId, oLogData).then(function (response) {
      		toastr.success(gtext("즐겨찾기가 추가되었습니다."));
		}).catch(function (response) {
			toastr.error(response.data.message);
		});
	};

	

	/*
	 * ***************************************************
	 *
	 * 즐겨찾기 추가, 삭제 토글
	 *
	 * ***************************************************
	 */	
	$rootScope.toggleFavoriteWithLogHandler = function (szUserId, oData, oLogData) {
		if (!$rootScope.member) {
			if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?"))) {
				// 로그인후에 자동으로 즐겨찾기 추가하기위함.
				$sessionStorage.favorateUserId = szUserId;
				$rootScope.login();
			}
			return;
		}

		// 현재 즐겨찾기 추가 상태이며, 즐겨찾기 삭제가 진행되야 할 경우
		if(oData.favorite_flag == 1){
			FavoriteService.delete(szUserId, oLogData).then(function(response){
				toastr.success(gtext("즐겨찾기가 삭제되었습니다."));
			}).catch(function (response) {
				toastr.error(response.data.message);
			});
			oData.favorite_flag =0;
			$scope.$digest();
		}else{
			delete $sessionStorage.favorateUserId;
			FavoriteService.add(szUserId, oLogData).then(function (response) {
				toastr.success(gtext("즐겨찾기가 추가되었습니다."));
			}).catch(function (response) {
				toastr.error(response.data.message);
			});
			if($rootScope.member.user_id != szUserId){
				// 즐겨찾기 추가 상태로 변환
				oData.favorite_flag = 1;
			}
			$scope.$digest();
		}
	};	


	$rootScope.toggleFavoriteHandler = function (szUserId, oData, oLogData) {
		if (!$rootScope.member) {
			if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?"))) {
				// 로그인후에 자동으로 즐겨찾기 추가하기위함.
				$sessionStorage.favorateUserId = szUserId;
				$rootScope.login();
			}
			return;
		}

		oLogData['join_cc'] = $rootScope.member.nationality_cd;
		
		// 현재 즐겨찾기 추가 상태이며, 즐겨찾기 삭제가 진행되야 할 경우
		if(oData.favorite_flag == 1){
			FavoriteService.delete(szUserId, oLogData).then(function(response){
				toastr.success(gtext("즐겨찾기가 삭제되었습니다."));
			}).catch(function (response) {
				toastr.error(response.data.message);
			});
			oData.favorite_flag =0;
			$scope.$digest();
		}else{
			delete $sessionStorage.favorateUserId;
			FavoriteService.add(szUserId, oLogData).then(function (response) {
				toastr.success(gtext("즐겨찾기가 추가되었습니다."));
			}).catch(function (response) {
				toastr.error(response.data.message);
			});
			if($rootScope.member.user_id != szUserId){
				// 즐겨찾기 추가 상태로 변환
				oData.favorite_flag = 1;
			}
			$scope.$digest();
		}
	};	

	/*
	 * ***************************************************
	 *
	 * 나중에보기
	 *
	 * ***************************************************
	 */
	$rootScope.bLightBoxVisible = false;
	$rootScope.bLaterViewAfterLayer = false; //나중에보기 안내 레이어
	$rootScope.szLaterViewAction = '';
	$rootScope.bLaterLayerChecked = false;
	$rootScope.szLaterMessage = 'live';


	// 나중에보기 추가 Handler
	$scope.addLaterViewHandler = function (scheme) {
		LaterviewFactory.sheme(scheme).add().then(function () {
			console.log('later called');
		}, function (error) {
			console.log('error', error);
		})
		return true;
	};

	// 나중에보기 안내 레이어 닫기
	$scope.closeLaterLayer = function () {
		LaterviewFactory.close($scope.bLaterLayerChecked);
	};

	/*
	 * ***************************************************
	 *
	 * 액션 시트
	 *
	 * ***************************************************
	 */
	// 액션시트 노출여부.
	$rootScope.bShowActionSheet = false;
	// 액션시트 노출.
	$rootScope.showActionSheet = function(actionList, selectedAction, callback){
		if(Util.getOS() === "ios"){
			if($(window).height() > window.innerHeight){
				var nMargin  = $(window).height() - window.innerHeight;
				$rootScope.oMarginBottom = { 'margin-bottom' : nMargin + 'px'};
			} else {
				$rootScope.oMarginBottom = { 'margin-bottom' : '0px'};
			}
		}

		$rootScope.bShowActionSheet = true;
		$scope.oSelectedAction = selectedAction;
		$scope.actionCallback = callback;
		$scope.aActionList = angular.copy(actionList);
		$('body').addClass('action_sheet'); //액션시트에서 스크롤할때 body에 있는 스크롤까지 먹히는 것 방지
	}
	// 액션 선택 시.
	$rootScope.selectAction = function(action){
		$scope.oSelectedAction = action;
		$scope.actionCallback(action);

		$rootScope.closeActionLayer();
	}
	// 액션 시트 닫기
	$rootScope.closeActionLayer = function(){
		if ($scope.$$phase == "$apply" || $scope.$$phase == "$digest") {
			$scope.actionCallback = null;
			$scope.aActionList = null;
			$rootScope.bShowActionSheet = false;
		} else {
			$scope.$apply(function() {
				$scope.actionCallback = null;
				$scope.aActionList = null;
				$rootScope.bShowActionSheet = false;
			});
		}
		$('body').removeClass('action_sheet'); //액션시트에서 스크롤할때 body에 있는 스크롤까지 먹히는 것 방지
	}


	/*
	 * ***************************************************
	 *
	 * 상단 서브메뉴
	 *
	 * ***************************************************
	 */
	$rootScope.bShowSubmenu = false;
	// 서브메뉴 초기화.
	$rootScope.initSubmenu = function(){
		$rootScope.aSubmenu = null;
		$rootScope.oSelectedSubmenu = null;
	}
	$rootScope.initSubmenu();

	// 서브메뉴 선택.
	$rootScope.clickSubmenu = function(oSubmenu){
		$rootScope.$broadcast('clickSubmenuEvent', oSubmenu);
	}
	// 서브메뉴 노출여부.
	$rootScope.$watch('aSubmenu', function (newValue, oldValue){
		// console.log('$state.current',$state.current);
		if ($rootScope.aSubmenu != null && $rootScope.aSubmenu.length > 0) {
			$rootScope.bShowSubmenu = true;
			if($state.current.name == 'video'){ //style 적용되었다면 제거
				$scope.oContentWrapPadding = '';
			}
			else{
				$scope.oContentWrapPadding = {
					'padding' : '48px 0 60px'
				}
			}
		}
		else {
			$rootScope.bShowSubmenu = false;

		}
	}, true);

	// 메뉴 활성화 효과 처리
	$scope.getMenuActiveClass = function(menu){
		var aUrl = Util.parseURL(menu.scheme);
		var bActive = false;
		if(aUrl.host === 'go' && aUrl.segments[0] !== 'webview'){
			if($state.current.name === 'home' && aUrl.segments[0] === 'live'){
				bActive = true;
			} else if ($state.current.name === aUrl.segments[0]){
				bActive = true;
			}
		} else {
			if($scope.selectMenu == menu){
				bActive = true;
			}
		}
		return bActive? 'active' : '';
	}

	// 알림페이지 이동
	$scope.notificationInfoHandler = function () {
		$('body').removeClass('menu_on');
		$rootScope.bNotificationClick = true;
		$state.go("notification");
		//Util.action("afreeca://go/notification");
	}
	
	//액션시트 > 재생목록에 담기
	$scope.aPlaylists = [];

    $scope.getMakingPlaylist = function(nTitleNo, nPage, nLimit){
		if (!$rootScope.member) {
			if (confirm(gtext("재생목록에 담으시려면\n로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?"))) {
				$rootScope.login();
			}
			return false;
		}

        var requestData = {
            'szWork' : "get_making_playlist",
            'type' : 'all',
            'sorttype' : 'recent_date',
            'title_no' : nTitleNo,
            'nPage' : nPage,
            'nLimit' : nLimit
        };
		
        VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
			$elPlayListBox = $('#add_playlist_layer .PlayListBox');

			if(oRes.total_cnt == 0) {
				$('.nonList').show();
			}
			else {
				$('.nonList').hide();

				// 1페이지 불러올 때 초기화
				if(nPage == 1) {
					$scope.aPlaylists = [];
					$scope.checkValue = [];
	
					$elPlayListBox.data("page", nPage);
					$('.PlayListBox').animate({ scrollTop: 0 }, 500);
				}

				// 1페이지 이거나 더 가져올 페이지가 있을 때
				if(nPage == 1 || $elPlayListBox.data("has_more") != "N") {
					angular.forEach(oRes.playlists, function(oPlaylist){
						$scope.checkValue.push({
							id: oPlaylist.idx,
							check: "N"
						});
	
						$scope.aPlaylists.push(oPlaylist);
					});
				}

				$elPlayListBox.data("has_more", oRes.has_more ? "Y" : "N" );
			}

            $elPlayListBox.off().on("scroll",function(){
                var scrollH = parseInt($(this).height());
                var scrollT = parseInt($(this).scrollTop());
                var totalHeight = parseInt($(this).prop('scrollHeight'));
                
                if (totalHeight <= (scrollH+scrollT) && oRes.has_more) {
                    var nPage =  parseInt($(this).data("page"))+1;
                    $(this).data("page", nPage);
                    $scope.getMakingPlaylist(nTitleNo, nPage, nLimit);
                }
            })
            
			$('#addPlaylist').data('title-no', nTitleNo);
			$("#add_playlist_layer").addClass("on");
			$('body').addClass('action_sheet'); //액션시트에서 스크롤할때 body에 있는 스크롤까지 먹히는 것 방지
			
			$scope.bAddPlaylist = true;
            $scope.$digest();
        });
	}
	
	// 재생목록 > dim
	$scope.clickDim = function(){
		if($scope.$$phase == '$apply' || $scope.$$phase == '$digest') {
			$scope.bAddPlaylist = false;
		}
		else {
			$scope.$apply(function() {
				$scope.bAddPlaylist = false;
			});
		}
		$('body').removeClass('action_sheet');
	}

	// 재생목록 > dim
	$(document).on('touchmove','#playlistDim',function(e){
		e.preventDefault;
		$scope.clickDim();
	})

    $scope.sharePlaylistList = function(nPlaylistIdx, szAuth) {
		if (!$rootScope.member) {
			var text = (szAuth == 'OPEN_FAN') ? "애청자 이상만 보실 수 있습니다.\n로그인 하시겠습니까?" : "재생목록을 저장하시려면\n로그인이 필요합니다.\n로그인하시겠습니까?";

			if (confirm(gtext(text))) {
				$rootScope.login();
			}
			
			return false;
		}

        var requestData = {
            'szWork' : "share_playlist_bj_list",
            'nPlaylistIdx' : nPlaylistIdx
        };
        
        VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
			alert(oRes.msg);
        });
	}

	/**
	 * 후원 툴팁 닫기
	 */
	$scope.closeSponserTooltip = function(){
		$scope.bSponserTooltipToggle = false;
	}

	/**
	 * 후원페이지 이동
	 */
	$scope.goSponserPage = function(){
		window.collector.click({
			code_type : 'main_popup',
			button_type: 'goto_sponseritem',
			to_url: M_AFREECATV+"/itemstore/a/starballoon",
		});
		location.href = M_AFREECATV+"/itemstore/a/starballoon";
	}

	// 중국어 || 영어 서비스 셋팅
	$scope.setLanguage();

	// 로그인 정보
	LoginManager.init();

	$scope.checkNewNote();

	//헤더 알림뱃지 갱신
	$scope.checkNewNotification();

	// 유입경로 처리 함수
	$rootScope.sendInflowLog = function (codeType, bjId, contentNo, addData, paramPathKey, sendLogLength, szReferer){
		Util.sendInflowLog(codeType, bjId, contentNo, addData, paramPathKey, sendLogLength, szReferer);
	}

	// 직접 유입경로 값 설정
	$rootScope.directVodInflowPath = function (bjId, contentNo, addData){
		Util.directVodInflowPath(bjId, contentNo, addData);
	}

	// 사업자 정보 열기,닫기 flag 변경 함수
	$scope.InfoOpen = function (bOpen) {
		$scope.bInfoOpen = !bOpen;
		if(!bOpen) {
			setTimeout(function () {
				window.scrollTo({left : document.querySelector("#mainFooter").offsetLeft, top : document.querySelector("#mainFooter").offsetTop + document.querySelector(".copyrigfht").offsetTop, behavior: 'smooth'});
			}, 50);
		}
	}

	// 이용약관 url 이동 함수
	$scope.goTermsOfUse = function () {
		var szLang = gettextCatalog.currentLanguage != 'ko_KR' ? "_en" : "";
		var szUrl = WWW_AFREECATV + '/policy/mobile/policy1' + szLang + ".html";
		window.open(szUrl, "_SELF");
		return false;
	}

	// 개인정보처리 수정 url 이동 함수
	$scope.goPrivacyPolicy = function () {
		var szLang = gettextCatalog.currentLanguage != 'ko_KR' ? "_en" : "";
		var szUrl = WWW_AFREECATV + '/policy/mobile/policy2' + szLang + ".html";
		window.open(szUrl, "_SELF");
		return false;
	}
}

module.exports = MainController;
